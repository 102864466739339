export class Post {
  id: string;
  title: string;
  summary: string;
  tags: string[] = [];
  content: string;
  isPublic: boolean;
  datePublished: string;

  constructor(id: string = undefined, title: string = undefined, summary: string = undefined, tags: string[] = [], content: string = undefined, isPublic: boolean = false) {
    this.id = id;
    this.title = title;
    this.summary = summary;
    this.tags = tags;
    this.content = content;
    this.isPublic = isPublic;
  }
}
