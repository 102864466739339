import { Component, OnInit } from '@angular/core';
import { Post } from 'src/app/models';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {
  post: Post = new Post();

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.post = <Post>this.route.snapshot.data.post;
  }

}
