import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../services';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;

  constructor(private sharedService: SharedService, private router: Router, private afAuth: AngularFireAuth) { }

  login() {
    this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password).then(response => {
      this.router.navigateByUrl('admin/dashboard');
    });
  }

  logout() {
    this.afAuth.auth.signOut();
  }

  ngOnInit() {
    if (this.sharedService.getValue('uid')) {
      this.router.navigateByUrl('admin/dashboard');
    }
  }

}
