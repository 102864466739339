import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Post } from '../models';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetaService } from '../services/meta.service';

@Injectable({ providedIn: 'root' })
export class PostResolver implements Resolve<Post> {
    constructor(private db: AngularFirestore, private metaService: MetaService) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.metaService.setById(route.params.id);
        return this.db.collection('posts').doc(route.params.id).get().pipe(
            map(
            res => {
              let post = <Post>res.data();
              //this.metaService.setCard(post.title, post.summary);
              return post;
            },
            err => {
              return err;
            }
            )
          );
    }
}
