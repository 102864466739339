import { Component, OnInit, Input } from '@angular/core';
import { Post } from '../../models';

@Component({
  selector: 'post-panel',
  templateUrl: './post-panel.component.html',
  styleUrls: ['./post-panel.component.scss']
})
export class PostPanelComponent implements OnInit {
  @Input() post: Post;
  constructor() { }

  ngOnInit() {}

  getTagList() {
    return this.post.tags.join(', ');
  }

}
