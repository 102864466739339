import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class MetaService {

  constructor(private meta: Meta, private title: Title) { }

  setCard(title: string, description: string) {
    this.meta.removeTag("name='og:title'");
    this.meta.removeTag("name='og:description'");
    this.meta.removeTag("name='twitter:card'");
    this.meta.removeTag("name='og:image'");

    this.meta.addTags([
      { name: 'twitter:card', content: 'summary' },
      { name: 'og:title', content: title },
      { name: 'og:description', content: description },
      { name: 'og:image', content: 'assets/icons/icon-192x192.png' }
    ]);

    this.title.setTitle(title);
  }

  setById(id) {
    let mapping = mappings.find(m => m.id === parseInt(id));
    this.setCard(mapping.name, mapping.description);
  }

}

export class MetaMapping {
  id: number;
  name: string;
  description: string;

  constructor(id, name, description) {
    this.id = id;
    this.name = name;
    this.description = description;
  }
}

export const mappings: { id: number, name: string, description: string }[] = [
  new MetaMapping(1573167758003, 'Fun With Fractals', 'Using Rust, Wasm, and some simple math to create fractal images in the browser.'),
  new MetaMapping(1560903739793, 'My Journey Into ECS', 'Never heard of ECS or want to know more? Let\'s talk about the difficulties in making video games, and how ECS emerged as my favorite architectural pattern for creating them.'),
  new MetaMapping(1560409598784, 'Code Challenge: Build A Blog', 'I took part in a two-week code challenge to build a blog. Here\'s what I did and why'),
];


