import { EditPostComponent } from './edit-post.component';
import { Routes } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';

export let EditPostRoutes: Routes = [
	{
		path: 'edit-post/:id',
		component: EditPostComponent,
		canActivate: [AngularFireAuthGuard]
	}
];
