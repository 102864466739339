import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { 
  PostPanelComponent, 
  HomeComponent, 
  AdminComponent, 
  DashboardComponent, 
  LoginComponent, 
  EditPostComponent
} from './components';
import { SharedService } from './services';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { firebaseConfig, environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';
import { BlogComponent } from './components/views/blog/blog.component';
import { PostComponent } from './components/views/post/post.component';
import { SocialMediaComponent } from './components/social-media/social-media.component';
import { MenuComponent } from './components/menu/menu.component';
import { AboutComponent } from './components/views/about/about.component';
import { ProjectsComponent } from './components/views/projects/projects.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MetaService } from './services/meta.service';

@NgModule({
  declarations: [
    AppComponent,
    PostPanelComponent,
    HomeComponent,
    AdminComponent,
    DashboardComponent,
    LoginComponent,
    EditPostComponent,
    BlogComponent,
    PostComponent,
    SocialMediaComponent,
    MenuComponent,
    AboutComponent,
    ProjectsComponent,
    SpinnerComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireStorageModule,
    HttpClientModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [SharedService, AngularFireAuth, AngularFireAuthGuard, AngularFirestore, MetaService],
  bootstrap: [AppComponent]
})
export class AppModule { }
