import { Component, OnInit } from '@angular/core';
import { Post } from 'src/app/models';
import { AngularFirestore } from '@angular/fire/firestore';
import { MetaService } from 'src/app/services/meta.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  posts: Post[] = [];

  constructor(private db: AngularFirestore, private metaService: MetaService) { }

  ngOnInit() {
    this.db.collection('posts').valueChanges().subscribe((x: Post[]) => 
      this.posts = x.filter(post => post.isPublic).sort((p1,p2): number => {
        if(parseInt(p1.id) < parseInt(p2.id)) {
          return 1;
        } else if (parseInt(p1.id) > parseInt(p2.id)) {
          return -1
        } else {
          return 0;
        }
      })
    );

    this.metaService.setCard('The Blog', 'Thoughts and ramblings of a developer.');
  }
}
