import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Post } from '../../../models';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  posts: Post[] = [];

  constructor(private router: Router, private db: AngularFirestore, private afAuth: AngularFireAuth) { }

  ngOnInit() {
    this.db.collection('posts').valueChanges().subscribe((x: Post[]) => this.posts = x);
  }

  newPost(){
    this.router.navigateByUrl('admin/edit-post/new');
  }

  editPost(id: any){
    this.router.navigateByUrl('admin/edit-post/' + id);
  }

  logout() {
    this.afAuth.auth.signOut().then(x => {
      this.router.navigateByUrl('admin/login');
    });
  }

}
