import { Component, OnInit } from '@angular/core';
import { Post } from '../../../models';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

@Component({
  selector: 'edit-post',
  templateUrl: './edit-post.component.html',
  styleUrls: ['./edit-post.component.scss']
})
export class EditPostComponent implements OnInit {
  post: Post = new Post();

  constructor(private db: AngularFirestore, private route: ActivatedRoute, private router: Router, private storage: AngularFireStorage) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (params['id'] === 'new') {
        this.post = new Post();
        this.post.isPublic = false;
      } else {
        this.db.collection('posts').valueChanges().subscribe((x: Post[]) => { 
          this.post = x.find(x => x.id === params['id']); 
          this.post.tags = this.post.tags ? this.post.tags : [] 
        });
      }
    });
  }

  savePost() {
    if (!this.post.datePublished && this.post.isPublic) {
      this.post.datePublished = moment().format('MM/DD/YYYY').toString();
    }
      if (this.post.id) {
        this.db.collection('posts').doc(this.post.id).set(<any>Object.assign({}, this.post));
      } else {
        this.post.id = `${new Date().getTime()}`;
        this.db.collection('posts').doc(`${this.post.id}`).set(<any>Object.assign({}, this.post));
      }
      this.router.navigateByUrl('admin/dashboard');
  }

  toKebabCase(str: string) {
    return str &&
      str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('-');
  }

  // addContent(type: number) {
  //   this.post.content = this.post.content ? this.post.content : [];
  //   this.post.content.push(new Content(type, this.post.content.length, '', ' '));
  // }

  // saveImage(file: File, content: Content) {
  //   const filePath = `images/${file.name}`;
  //   this.storage.upload(filePath, file).then(x => {
  //     content.Value = x.downloadURL;
  //     content.ImageName = file.name;
  //   });
  // }

  // deleteContent(content: Content, idx: number) {
  //   if (content.Type === ContentType.Image) {
  //     this.storage.storage.refFromURL(content.Value).delete();
  //   }
  //   this.post.content.splice(idx, 1);
  // }
}
