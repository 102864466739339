import { DashboardComponent } from './dashboard.component';
import { Routes } from '@angular/router';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/auth-guard';
import { canActivate } from '@angular/fire/auth-guard';

const redirectUnauthorizedToLogin = redirectUnauthorizedTo(['admin/login']);

export let DashboardRoutes: Routes = [
	{
		path: 'dashboard',
		component: DashboardComponent,
		...canActivate(redirectUnauthorizedToLogin)
	}
];
