import { AdminComponent } from './admin.component';
import { Routes } from '@angular/router';
import { DashboardRoutes } from '../dashboard/dashboard.routes';
import { EditPostRoutes } from '../edit-post/edit-post.routes';
import { LoginRoutes } from '../login/login.routes';

export let AdminRoutes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      ...DashboardRoutes,
      ...EditPostRoutes,
      ...LoginRoutes
    ]
  }
];
